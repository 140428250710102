import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
    Container,
    Header,
    Title,
    Tabs,
    Tab,
    Wrapper,
    TabDescription,
    TabContent,
    More,
    Dropdown,
    DropdownItem,
    LinkOnMobile,
    LinkBottom,
    TitleWrapper
} from './WithTabs.styled';
import { BaseOffsetsUI, BaseUI, IMetrika, IMetrikaSample, LinkI } from '@/interfaces';
import ResizeObserver from 'resize-observer-polyfill';
import { InView } from 'react-intersection-observer';
import { SvgElements } from '@/helpers/icons';
import { sendMetrik } from '@/helpers';
import Link from 'next/link';
import Arrow from '@/icons/arrow.svg';
import { Colors } from '@/style/colors';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import useIsMatches from '@/hooks/useIsMatches';
import breakpoints from '@/style/breakpoints';

export interface WithTabsProps extends BaseUI, BaseOffsetsUI, IMetrika {
    title?: string;
    description?: string[];
    tabs: string[];
    link?: LinkI;
    bgColor?: Colors;
    content: ReactElement[];
    withoutSideOffsets?: boolean;
    headerInRow?: boolean;
    isNumbered?: boolean;
    tabsEvents?: ((tab: string) => void)[];
    activeTabId?: number;
    metriksSampleTabs?: IMetrikaSample;
    disableResize?: boolean;
}

const ClassNames = {
    IS_SHOW_ALL: '_is-show-all',
    SHOW_MORE: 'show-more',
    MOBILE_HIDDEN_TAGS: 'mobile-hidden-tags',
    IS_ACTIVE: '_is-active'
} as const;

const WithTabs: React.FC<WithTabsProps> = ({
    className,
    title,
    description,
    tabs,
    link,
    content,
    bgColor,
    metriksSample,
    metriksSampleTabs,
    topOffset,
    bottomOffset,
    withoutSideOffsets,
    headerInRow = false,
    isNumbered = false,
    tabsEvents,
    activeTabId = 0,
    disableResize = false
}) => {
    const [activeTab, setActiveTab] = useState<string | null>(tabs[activeTabId]);
    const [mobilePrevTabs, setMobilePrevTabs] = useState<string[]>([]);
    const [visibleTabs, setVisibleTabs] = useState<string[]>(tabs);
    const [hiddenTabs, setHiddenTabs] = useState<string[]>([]);
    const [showMore, setShowMore] = useState(false);
    const [tabAdjustment, setTabAdjustment] = useState(false);
    const [heightWrapper, setHeightWrapper] = useState(0);
    const [transitionTabWrapper, setTransitionTabWrapper] = useState('unset');
    const headerRef = useRef<HTMLDivElement | null>(null);
    const tabsRef = useRef<HTMLDivElement | null>(null);
    const moreRef = useRef<HTMLButtonElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { locale } = useRouter();
    const isMobile = useIsMatches('md');
    const activeTabRef = useRef<HTMLButtonElement | null>(null);
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const scrollContainer = () => {
        if (timerRef.current) clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            if (!tabsRef.current || !tabsRef) return;

            const parent = activeTabRef.current?.parentElement?.classList.contains(ClassNames.MOBILE_HIDDEN_TAGS)
                ? activeTabRef.current.parentElement
                : activeTabRef.current;

            parent?.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
        }, 100);
    };

    const updateTabsVisibility = () => {
        if (!tabsRef.current) return;

        if (window.innerWidth < breakpoints.md) {
            if (tabsRef.current?.classList.contains(ClassNames.IS_SHOW_ALL)) {
                tabsRef.current.style.maxWidth = '';
                scrollContainer();
            } else {
                tabsRef.current?.scrollTo(0, 0);
                const buttons = Array.from(tabsRef.current?.querySelectorAll('button'));
                activeTabRef.current = buttons.find((item) => item.classList.contains(ClassNames.IS_ACTIVE)) || buttons[0];
                tabsRef.current.style.maxWidth = `${(activeTabRef.current?.getBoundingClientRect().width ?? 0) + 80}px`;
            }
            return;
        }

        tabsRef.current.style.maxWidth = '';

        if (headerRef.current) {
            setVisibleTabs(tabs);
            setHiddenTabs([]);

            const tabItems = Array.from(tabsRef.current.children) as HTMLElement[];
            const containerWidth = headerRef.current.offsetWidth;
            let totalWidth = 0;
            let lastVisibleIndex = tabItems.length - 1;

            for (let i = 0; i < tabItems.length; ++i) {
                totalWidth += tabItems[i].offsetWidth;

                if (totalWidth + 75 > containerWidth) {
                    lastVisibleIndex = i;
                    break;
                }
            }

            setVisibleTabs(tabs.slice(0, lastVisibleIndex));
            setHiddenTabs(tabs.slice(lastVisibleIndex));
        }
    };

    useEffect(() => {
        let resizeObserver: ResizeObserver;

        if (headerRef.current && tabsRef.current && !disableResize) {
            const resizeHandler = debounce(() => updateTabsVisibility(), 200);
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(headerRef.current);

            updateTabsVisibility();
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, [tabs, disableResize]);

    useEffect(() => {
        if (!isMobile || disableResize) return;
        let activeTabIndex = tabs.indexOf(String(activeTab));
        if (activeTabIndex === -1) activeTabIndex = 0;

        setMobilePrevTabs(tabs.slice(0, activeTabIndex));
        setVisibleTabs([tabs.find((tab) => activeTab === tab) || tabs[0]]);
        setHiddenTabs(tabs.slice(activeTabIndex + 1));
        setShowMore(false);
    }, [isMobile, activeTab, disableResize]);

    useEffect(() => {
        if (tabAdjustment) adjustingTabs();
    }, [tabAdjustment]);

    const handleTabClick = (tab: string) => {
        const visibleIndex = visibleTabs.indexOf(tab);
        if (visibleIndex !== -1) return;

        const hiddenIndex = hiddenTabs.indexOf(tab);
        if (hiddenIndex !== -1) {
            const newVisibleTabs = [...visibleTabs];
            const newHiddenTabs = [...hiddenTabs];

            const lastVisibleTab = newVisibleTabs.pop();
            newVisibleTabs.push(tab);

            if (lastVisibleTab) {
                newHiddenTabs[hiddenIndex] = lastVisibleTab;
                newHiddenTabs.unshift(newHiddenTabs.splice(hiddenIndex, 1)[0]);
            } else {
                newHiddenTabs.splice(hiddenIndex, 1);
            }

            setVisibleTabs(newVisibleTabs);
            setHiddenTabs(newHiddenTabs);

            setTabAdjustment(true);
        }
    };

    const adjustingTabs = () => {
        if (headerRef.current && tabsRef.current) {
            const newVisibleTabs = [...visibleTabs];
            const newHiddenTabs = [...hiddenTabs];

            const tabItems = Array.from(tabsRef.current.children) as HTMLElement[];
            const containerWidth = headerRef.current.offsetWidth;
            let totalWidth = 0;

            for (let i = 0; i < tabItems.length; ++i) {
                totalWidth += tabItems[i].offsetWidth;

                if (totalWidth > containerWidth) {
                    const firstVisibleTab = newVisibleTabs.shift();
                    if (firstVisibleTab) newHiddenTabs.push(firstVisibleTab);
                    break;
                }
            }

            setVisibleTabs(newVisibleTabs);
            setHiddenTabs(newHiddenTabs);

            setTabAdjustment(false);
        }
    };

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        setTransitionTabWrapper('height 0.3s ease-in-out');

        if (containerRef.current) {
            const resizeHandler = () => {
                if (containerRef.current) setHeightWrapper(containerRef.current.offsetHeight);
            };
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(containerRef.current);
            resizeHandler();
        }
        const transitionTimeout = setTimeout(() => {
            setTransitionTabWrapper('unset');
        }, 300);

        return () => {
            resizeObserver?.disconnect();
            clearTimeout(transitionTimeout);
        };
    }, [activeTab]);

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (
            tabsRef.current &&
            moreRef.current &&
            !tabsRef.current.contains(event.target as Node) &&
            !moreRef.current.contains(event.target as Node)
        ) {
            setShowMore(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (disableResize) return;
        requestAnimationFrame(() => {
            requestAnimationFrame(updateTabsVisibility);
        });
    }, [showMore, disableResize]);

    return (
        <InView triggerOnce={true} threshold={0.25}>
            {({ inView, ref }) => (
                <Container
                    ref={ref}
                    className={`${className} ${inView ? '' : '_prepare'}`}
                    bottomOffset={bottomOffset}
                    topOffset={topOffset}
                >
                    <Header ref={headerRef} headerInRow={headerInRow}>
                        <TitleWrapper>
                            {title && <Title dangerouslySetInnerHTML={{ __html: title }}></Title>}
                            {link && (
                                <Link href={link.href} passHref prefetch={false}>
                                    <LinkOnMobile
                                        onClick={
                                            metriksSample &&
                                            (() =>
                                                sendMetrik(metriksSample.event, metriksSample.category, {
                                                    [metriksSample.action]: link.text
                                                }))
                                        }
                                    >
                                        {locale === 'ru' ? 'Все' : 'All'}
                                        <Arrow />
                                    </LinkOnMobile>
                                </Link>
                            )}
                        </TitleWrapper>

                        <Tabs ref={tabsRef} isMobile={isMobile} showAllTags={showMore} className={showMore ? ClassNames.IS_SHOW_ALL : ''}>
                            {isMobile && !!mobilePrevTabs.length && (
                                <span className={`${ClassNames.MOBILE_HIDDEN_TAGS} ${showMore ? ClassNames.SHOW_MORE : ''}`}>
                                    {mobilePrevTabs.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            className={activeTab === tab ? ClassNames.IS_ACTIVE : ''}
                                            active={activeTab === tab}
                                            onClick={() => {
                                                setActiveTab(tab);
                                                tabsEvents?.slice(0, mobilePrevTabs.length)[index]?.(tab);
                                                metriksSampleTabs &&
                                                    sendMetrik(metriksSampleTabs.event, metriksSampleTabs.category, {
                                                        [metriksSampleTabs.action]: tab
                                                    });
                                            }}
                                        >
                                            {isNumbered ? `${index + 1} ${locale === 'ru' ? 'шаг' : 'step'}` : tab}
                                        </Tab>
                                    ))}
                                </span>
                            )}

                            {visibleTabs.map((tab, index) => (
                                <Tab
                                    key={index}
                                    className={activeTab === tab ? ClassNames.IS_ACTIVE : ''}
                                    active={activeTab === tab}
                                    type={tabsEvents ? 'button' : undefined}
                                    onClick={() => {
                                        setActiveTab(tab);
                                        tabsEvents?.slice(mobilePrevTabs.length, visibleTabs.length)[index]?.(tab);
                                        metriksSampleTabs &&
                                            sendMetrik(metriksSampleTabs.event, metriksSampleTabs.category, {
                                                [metriksSampleTabs.action]: tab
                                            });
                                    }}
                                >
                                    {isNumbered ? `${index + 1} ${locale === 'ru' ? 'шаг' : 'step'}` : tab}
                                </Tab>
                            ))}

                            {isMobile && !!hiddenTabs.length && (
                                <span className={`${ClassNames.MOBILE_HIDDEN_TAGS} ${showMore ? ClassNames.SHOW_MORE : ''}`}>
                                    {hiddenTabs.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            className={activeTab === tab ? ClassNames.IS_ACTIVE : ''}
                                            active={activeTab === tab}
                                            onClick={() => {
                                                setActiveTab(tab);
                                                tabsEvents?.slice(visibleTabs.length)[index]?.(tab);
                                                metriksSampleTabs &&
                                                    sendMetrik(metriksSampleTabs.event, metriksSampleTabs.category, {
                                                        [metriksSampleTabs.action]: tab
                                                    });
                                            }}
                                        >
                                            {isNumbered ? `${index + 1} ${locale === 'ru' ? 'шаг' : 'step'}` : tab}
                                        </Tab>
                                    ))}
                                </span>
                            )}
                            <More
                                ref={moreRef}
                                show={isMobile && showMore && !disableResize ? false : hiddenTabs.length > 0 || mobilePrevTabs.length > 0}
                                open={showMore}
                                onBlur={() => !isMobile && setShowMore(false)}
                                onClick={() => {
                                    setShowMore((prevState) => !prevState);
                                }}
                                isMobile={isMobile}
                                type={tabsEvents ? 'button' : undefined}
                            >
                                <span>{locale === 'ru' ? 'Ещё' : 'More'}</span>
                                <span>{SvgElements['arrowUp']}</span>
                                {!isMobile && (
                                    <Dropdown show={showMore} className="drop-down">
                                        {hiddenTabs.map((tab, index) => (
                                            <DropdownItem
                                                key={index}
                                                active={activeTab === tab}
                                                onClick={() => {
                                                    setActiveTab(tab);
                                                    handleTabClick(tab);
                                                    tabsEvents?.slice(visibleTabs.length)[index]?.(tab);
                                                }}
                                            >
                                                {tab}
                                            </DropdownItem>
                                        ))}
                                    </Dropdown>
                                )}
                            </More>
                        </Tabs>
                    </Header>

                    <Wrapper
                        style={{ height: `${heightWrapper}px`, transition: `${transitionTabWrapper}` }}
                        withoutSideOffsets={withoutSideOffsets}
                    >
                        <div ref={containerRef}>
                            {content?.map((item, index) => (
                                <>
                                    <TabContent
                                        className={activeTab === tabs[index] ? '_active-tab' : ''}
                                        key={index}
                                        active={activeTab === tabs[index]}
                                        bgColor={bgColor}
                                    >
                                        {description && <TabDescription dangerouslySetInnerHTML={{ __html: description[index] }} />}
                                        {item}
                                    </TabContent>
                                </>
                            ))}
                        </div>
                    </Wrapper>

                    {link && (
                        <LinkBottom
                            {...link}
                            type="fill-white"
                            clickOnButton={
                                metriksSample &&
                                (() =>
                                    sendMetrik(metriksSample.event, metriksSample.category, {
                                        [metriksSample.action]: link.text
                                    }))
                            }
                        />
                    )}
                </Container>
            )}
        </InView>
    );
};

export default WithTabs;
